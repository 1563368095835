import { useContext } from "react";

import { PropTypes } from "prop-types";
import { LuxeContext } from "../../context/LuxeProvider";
import { CartContext } from "../../context/CartProvider";

import { applyCartDiscounts } from "../cartDiscounts";

import { useCurrentCountryCode } from "../../hooks/usePrices";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useIsBannedProduct from "../../hooks/useIsBannedProduct";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";
import useSalesColors from "../../hooks/useSalesColors";

import QuantityPercentProgressTrackerGift from "../al_components/QuantityPercentProgressTrackerGift";
import RichTextContentful from "../RichTextContentful";

import { Country } from "../../constants/Country";
import { ComponentType } from "../../constants/ComponentType";

import "./saleBlock/styles.scss";

function SaleBlock({ tags, saleBlocks }) {
  const { luxe } = useContext(LuxeContext);
  const { cart } = useContext(CartContext);

  const discountsInfo = useDiscountsInfo();
  const isBannedProduct = useIsBannedProduct(tags, discountsInfo);
  const countryCode = useCurrentCountryCode();
  const salesColors = useSalesColors();

  // const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const sideCartInfo = applyCartDiscounts(cart.lines, discountsInfo);

  const isLuxe = luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC;

  const matchedSaleBlock = saleBlocks.find((saleBlock) => {
    if (saleBlock.category === "ROW") {
      return countryCode !== Country.US;
    }

    if (saleBlock.category === "Luxe") {
      return countryCode === Country.US && isLuxe;
    }

    if (saleBlock.category === "Guest") {
      return countryCode === Country.US && !isLuxe;
    }

    return false;
  });

  const { title, subtitle, content, backgroundColors, textColor } = matchedSaleBlock;

  const defaultColors = {
    text: "#2D2927",
    background: "#F5F1F2",
  };

  const background =
    useResponsiveWithHydrationFix(backgroundColors?.mobile, backgroundColors?.desktop) ||
    defaultColors.background;

  const color =
    useResponsiveWithHydrationFix(textColor?.mobile, textColor?.desktop) || defaultColors.text;

  if (!matchedSaleBlock || matchedSaleBlock?.title === "null") {
    return null;
  }

  if (isBannedProduct) {
    return null;
  }

  return (
    <div className="sale-block-wrapper" style={{ backgroundColor: background, color }}>
      {/* <button type="button" className="faq-icon" onClick={() => setInfoPopupOpen(!infoPopupOpen)}>
        <IconInfo width="23" fill="none" stroke="#2D2927" />
      </button> */}
      <h3 className="h3 h3--bold  m-0 mb-10">{title}</h3>
      {subtitle && <p className="subtitle">{subtitle}</p>}
      <QuantityPercentProgressTrackerGift
        eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
        componentType={ComponentType.PDP}
        saleColors={salesColors}
        isTextAbove={false}
      />
      {/* <SubscriptionPopUp isOpen={infoPopupOpen} onClose={() => setInfoPopupOpen(false)} /> */}
      {content?.raw && (
        <RichTextContentful input={content.raw} prefixClassName="sale-block-wrapper__content" />
      )}
    </div>
  );
}

SaleBlock.propTypes = {
  saleBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      content: PropTypes.shape({
        raw: PropTypes.string,
      }),
      backgroundColors: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
      textColor: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
    })
  ).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SaleBlock;
